import Vue from 'vue';
import VueApollo from 'vue-apollo';
// import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'

import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { OPERATION_WITHOUT_TOKEN } from '@/shared/constants';

// import { createApolloFetch } from 'apollo-fetch'
// https://github.com/apollographql/apollo-fetch

import { store } from '@/store';
import { version } from './../package.json';

// Install the vue plugin
Vue.use(VueApollo);

export const BASE_URL = process.env.BASE_URL;
const uri = `${process.env.VUE_APP_GQL}/graphql`;
const httpLink = createHttpLink({ uri });
let defaultRouter = null;
let defaultStore = null;
let isTokenExpired = false; // token flag

const acceptJsonMiddleware = new ApolloLink((operation, forward) => {
	if (!isTokenExpired || OPERATION_WITHOUT_TOKEN.includes(operation.operationName)) {
		// get the authentication token from local storage if it exists
		const token = store.getToken();

		operation.setContext(({ headers = {} }) => ({
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
				'X-Client-Version': version,
				accept: 'application/json'
			}
		}));

		isTokenExpired = false;

		return forward(operation);
	} else return null;
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (networkError && networkError.statusCode === 505) {
		defaultStore.dispatch('header/setErrorAppVersion', networkError.bodyText.trim());
		store.onLogout(null, false);
		defaultRouter.push('/pages/login').catch(() => {});
	} else if (networkError && networkError.statusCode === 401 && networkError.bodyText.trim() === 'Expired token') {
		store.onLogout(apolloClient, true);
		isTokenExpired = true; // Update the flag here
		defaultRouter.push({ path: '/pages/login', query: { se: true } }).catch(() => {});
	}
});

// Create apollo client
export const apolloClient = new ApolloClient({
	link: from([acceptJsonMiddleware, errorLink, httpLink]),
	cache: new InMemoryCache()
});

/**
 * Call this in the Vue app file
 *
 * @param {Object} [options={}]
 * @return {Function}
 */
export function createProvider(options = {}) {
	defaultRouter = options.router;
	defaultStore = options.store;

	// Create vue apollo provider
	const apolloProvider = new VueApollo({
		defaultClient: apolloClient
	});

	return apolloProvider;
}
