var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-item" },
    [
      _vm.computedShownFields.length > 0 && !_vm.horizontalMode
        ? _c("div", { staticClass: "header-item" }, [
            _vm.keyForTitle.replace(/[ \s]+/g, "") !== "" &&
            _vm.title.replace(/[ \s]+/g, "") !== ""
              ? _c("div", { staticClass: "table-title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "table-resume" },
              _vm._l(
                _vm.getListKeyValueItem(_vm.item, _vm.computedShownFields),
                function (resume, index) {
                  return _c("div", { key: index }, [
                    resume.value && resume.value !== ""
                      ? _c("div", { staticClass: "resume-item" }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v(_vm._s(resume.value)),
                          ]),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(resume.label)),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                }
              ),
              0
            ),
          ])
        : _vm.computedShownFields.length > 0 && _vm.horizontalMode
        ? _c("div", { staticClass: "header-item p-3" }, [
            _vm.keyForTitle.replace(/[ \s]+/g, "") !== "" &&
            _vm.title.replace(/[ \s]+/g, "") !== ""
              ? _c("h6", { staticClass: "m-0" }, [
                  _vm._v(
                    _vm._s(_vm.title) + " (X" + _vm._s(_vm.quantity) + ")"
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              _vm._l(
                _vm.getListKeyValueItem(_vm.item, _vm.computedShownFields),
                function (resume, index) {
                  return _c("div", { key: index }, [
                    resume.value && resume.value !== ""
                      ? _c("div", [
                          _c("h4", { staticClass: "m-0 mt-2" }, [
                            _vm._v(
                              _vm._s(resume.label) +
                                " - " +
                                _vm._s(resume.value)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                }
              ),
              0
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.toggleDetail,
              expression: "toggleDetail",
            },
          ],
          staticClass: "content-item",
        },
        [
          _vm.hasBadge && !_vm.hideStatus
            ? _c(
                "div",
                {
                  staticClass: "data-item even",
                  class: `${
                    _vm.computedShownFields.length === 0
                      ? "border-radius-top"
                      : ""
                  }`,
                },
                [
                  _c("div", { staticClass: "key" }),
                  _c("div", { staticClass: "value" }, [
                    _c(
                      "div",
                      {
                        staticClass: "status",
                        class: `${
                          _vm.isBadgeUseProps
                            ? _vm.item[_vm.badgeClass]
                            : _vm.badgeClass
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.isBadgeUseProps
                                ? _vm.item[_vm.badgeValue]
                                : _vm.badgeValue
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._l(_vm.getListKeyValueItem(_vm.item), function (cell, i) {
            return _c("div", { key: i }, [
              _c(
                "div",
                {
                  staticClass: "data-item",
                  class: `${
                    _vm.hasBadge
                      ? i % 2 === 0
                        ? "odd"
                        : "even"
                      : i % 2 === 0
                      ? "even"
                      : "odd"
                  } ${i === 0 && !_vm.hasBadge ? "border-radius-top" : ""} ${
                    i === _vm.getListKeyValueItem(_vm.item).length - 1 &&
                    !_vm.$hasSlot("children-actions")
                      ? "border-radius-bottom border-bottom"
                      : ""
                  }`,
                },
                [
                  _c("div", { staticClass: "key" }, [
                    _vm._v(_vm._s(cell.label)),
                  ]),
                  !cell.isSlot
                    ? _c("div", { staticClass: "value" }, [
                        _vm._v(_vm._s(cell.value)),
                      ])
                    : _c(
                        "div",
                        { staticClass: "value" },
                        [_vm._t(cell.key)],
                        2
                      ),
                ]
              ),
            ])
          }),
          _vm.$hasSlot("children-actions")
            ? _c(
                "div",
                { staticClass: "actions-item" },
                [_vm._t("children-actions", null, { item: _vm.item })],
                2
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.computedShownFields.length > 0
        ? _c("card-footer", {
            attrs: { "toggle-mode": _vm.toggleDetail },
            on: { "card-list-builder:toggle": _vm.toggleCardDetail },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }