import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

/**
 * Service to ping token in backend if is still available.
 * @returns String
 */
export const pingToken = async () => {
	try {
		const query = gql`
			query {
				CheckTokenValidity
			}
		`;
		const { data } = await apollo.query({ query, fetchPolicy: 'no-cache' });
		return data.CheckTokenValidity;
	} catch (e) {
		// console.log({ e });
		return false;
	}
};

/**
 * Service to expire user session on logout.
 * @returns Boolean
 */
export const logoutUser = async () => {
	try {
		const mutation = gql`
			mutation {
				Logout
			}
		`;
		const { data } = await apollo.mutate({ mutation });
		return data.Logout;
	} catch (e) {
		// console.log({ e });
		return false;
	}
};
