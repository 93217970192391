var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showSuggestChrome
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "b-alert",
                    {
                      attrs: { variant: "warning", show: "", dismissible: "" },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.FormMSG(4001, "Use")) + " "
                      ),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.FormMSG(4002, "Chrome browser"))),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.FormMSG(4003, "for better use")) +
                          ".\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.objectIsNotNull(_vm.error)
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c("b-alert", {
                    attrs: { variant: "danger", show: "", dismissible: "" },
                    domProps: { innerHTML: _vm._s(_vm.errorMessage) },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.warning.length > 0
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c("b-alert", {
                    attrs: { dismissible: "", variant: "danger", show: "" },
                    domProps: { innerHTML: _vm._s(_vm.warning) },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }