var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: _vm.componentRef,
      staticClass: "card card-collapse",
      class: { "is-openned": _vm.isOpen },
      attrs: { id: _vm.componentRef },
    },
    [
      _c(
        "div",
        { staticClass: "card-header", on: { click: _vm.handleOpenCollapse } },
        [
          _c("h4", { domProps: { innerHTML: _vm._s(_vm.title) } }),
          _c("i", { staticClass: "icon-arrow-right" }),
        ]
      ),
      _c(
        "b-card-body",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen",
            },
          ],
          staticClass: "collapse-card-body",
          class: { "is-openned": _vm.isOpen },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }