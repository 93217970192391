var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.useNewVersion
      ? _c("div", { staticClass: "data-card-details" }, [
          _vm.cloneItemsMap.length > 0 || _vm.hasInitialData
            ? _c(
                "div",
                { staticClass: "cols-12" },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.FormMSG(2, "Type to search ..."),
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.search($event)
                          },
                        },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _vm.filter.length === 0
                                ? _c("search", {
                                    staticClass: "icon",
                                    attrs: { color: "#06263E", size: 16 },
                                  })
                                : _c("x", {
                                    staticClass: "icon",
                                    attrs: { color: "#06263E", size: 16 },
                                    on: { click: _vm.reInitializeItems },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.cloneItemsMap.length > 0 || _vm.hasInitialData
            ? _c("div", { staticClass: "cols-12 mt-3" }, [
                !_vm.hideFilterOptions && _vm.$has(_vm.toggleFilterOptions)
                  ? _c(
                      "fieldset",
                      {
                        staticClass:
                          "card-inside fix-fieldset-card-inside-padding-bottom",
                      },
                      [
                        _c("legend", { staticClass: "card-inside" }, [
                          _vm._v(_vm._s(_vm.FormMSG(3, "Advanced search"))),
                        ]),
                        _c("b-form-group", {
                          staticClass: "advanced-search",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ ariaDescribedby }) {
                                  return [
                                    _c("b-form-radio-group", {
                                      attrs: {
                                        size: "lg",
                                        "aria-describedby": ariaDescribedby,
                                        options:
                                          _vm.toggleFilterOptions.choices,
                                        name: "advanced-search",
                                      },
                                      model: {
                                        value: _vm.currToggleFilterAction,
                                        callback: function ($$v) {
                                          _vm.currToggleFilterAction = $$v
                                        },
                                        expression: "currToggleFilterAction",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            703159046
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.cloneItemsMap.length > 0 || _vm.hasInitialData
            ? _c(
                "div",
                { staticClass: "mt-3" },
                _vm._l(_vm.cloneItemsMap, function (item, i) {
                  return _c(
                    "div",
                    { key: i },
                    [
                      _c(
                        "card-content",
                        {
                          attrs: {
                            fields: _vm.fields,
                            "omitted-fields": _vm.omittedFields,
                            "shown-fields": _vm.headerFields,
                            "horizontal-mode": _vm.horizontalMode,
                            "key-for-quantity": _vm.keyForQuantity,
                            "show-quantity-on-title": _vm.showQuantityOnTitle,
                            item: item,
                            "hide-status": _vm.hideStatus,
                            "has-badge": _vm.hasBadge,
                            "badge-value": _vm.badgeValue,
                            "is-badge-use-props": _vm.isBadgeUseProps,
                            "badge-class": _vm.badgeClass,
                            "key-for-title": _vm.keyForTitle,
                            "toggle-mode": _vm.toggleMode,
                          },
                        },
                        [
                          _vm._l(_vm.fields, function (field) {
                            return [
                              _c(
                                "template",
                                { slot: field.key },
                                [
                                  field.isSlot && field.required
                                    ? _vm._t(field.key, null, {
                                        item: item,
                                        index: i,
                                        error:
                                          _vm.$v.cloneItemsMap.$each[i][
                                            field.key
                                          ].$error,
                                        isLineValid:
                                          !_vm.$v.cloneItemsMap.$each[i]
                                            .$invalid,
                                      })
                                    : _vm._e(),
                                  field.isSlot && !field.required
                                    ? _vm._t(field.key, null, {
                                        item: item,
                                        index: i,
                                      })
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          }),
                          _c(
                            "template",
                            { slot: "children-actions" },
                            [_vm._t("actions", null, { item: item, index: i })],
                            2
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _c("span", { staticClass: "empty-data" }, [
                _vm._v(_vm._s(_vm.FormMSG(4, "No data found"))),
              ]),
        ])
      : _c(
          "div",
          { staticClass: "container-mobile form", class: _vm.customClass },
          [
            !_vm.hideFilterOptions
              ? _c(
                  "div",
                  [
                    _vm.$has(_vm.toggleFilterOptions)
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "toggle-filter-container" },
                              _vm._l(
                                _vm.toggleFilterOptions.choices,
                                function (toggle, i) {
                                  return _c(
                                    "b-button",
                                    {
                                      key: i,
                                      attrs: {
                                        size: "sm",
                                        variant: `${
                                          !_vm.isToggleFilterActive(
                                            toggle.value
                                          )
                                            ? "outline-"
                                            : ""
                                        }primary`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleToggleFilterAction(
                                            toggle.value
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t" +
                                          _vm._s(toggle.text) +
                                          "\n\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showFilter
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "my-2", attrs: { sm: "12" } },
                      [
                        _c(
                          "b-input-group",
                          [
                            _c("b-form-input", {
                              attrs: {
                                type: "text",
                                placeholder: _vm.FormMSG(
                                  2,
                                  "Type to search ..."
                                ),
                                autocomplete: "off",
                              },
                              on: {
                                keyup: [
                                  function ($event) {
                                    return _vm.search($event)
                                  },
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "delete",
                                        [8, 46],
                                        $event.key,
                                        ["Backspace", "Delete", "Del"]
                                      )
                                    )
                                      return null
                                    return _vm.deleteSearch($event)
                                  },
                                ],
                              },
                              model: {
                                value: _vm.filter,
                                callback: function ($$v) {
                                  _vm.filter = $$v
                                },
                                expression: "filter",
                              },
                            }),
                            _c(
                              "b-input-group-append",
                              [
                                _c(
                                  "b-input-group-text",
                                  [
                                    _vm.filter.length === 0
                                      ? _c("search", {
                                          staticClass: "icon",
                                          attrs: { color: "#06263E", size: 16 },
                                        })
                                      : _c("x", {
                                          staticClass: "icon cursor-pointer",
                                          attrs: { color: "#06263E", size: 16 },
                                          on: { click: _vm.reInitializeItems },
                                        }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.cloneItemsMap.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.cloneItemsMap, function (item, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        ref: "cardListLocal",
                        refInFor: true,
                        staticClass: "card mobile-card-list-item w-100 p-0",
                        staticStyle: { "background-color": "#f5f7f9" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCardClicked(item)
                          },
                        },
                      },
                      [
                        item
                          ? _c(
                              "div",
                              {
                                staticClass: "card-body",
                                class: { "p-0": _vm.$screen.width <= 992 },
                              },
                              [
                                !_vm.hideStatus
                                  ? _c("div", { staticClass: "head" }, [
                                      _c(
                                        "div",
                                        { staticClass: "left" },
                                        [
                                          item.validatedClass &&
                                          item.validatedStatus
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    `badge badge-${item.validatedClass}`,
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        item.validatedStatus
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._t("badges", null, {
                                            item: item,
                                            index: i,
                                          }),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.$hasSlot("info"),
                                              expression: "$hasSlot('info')",
                                            },
                                          ],
                                          staticClass: "info",
                                        },
                                        [
                                          _vm._t("info", null, {
                                            item: item,
                                            index: i,
                                          }),
                                        ],
                                        2
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "content py-0" },
                                  [
                                    _vm.styleMode === "table"
                                      ? _c("b-table", {
                                          staticClass: "py-0",
                                          attrs: {
                                            borderless: "",
                                            size: "sm",
                                            fields: _vm.fields,
                                            items: [item],
                                          },
                                        })
                                      : _c(
                                          "ul",
                                          {
                                            class: `list style-mode--${_vm.styleMode}`,
                                          },
                                          _vm._l(
                                            _vm.rendFieldsMap(item),
                                            function (cell, j) {
                                              return _c("li", { key: j }, [
                                                !_vm.isObject(cell)
                                                  ? _c("div", {
                                                      staticClass:
                                                        "d-flex align-items-center",
                                                      domProps: {
                                                        innerHTML: _vm._s(cell),
                                                      },
                                                    })
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                        staticStyle: {
                                                          "line-height":
                                                            "2.25rem",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "customLabel",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(cell.label)
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "customText",
                                                          },
                                                          [
                                                            _vm._t(
                                                              cell.key,
                                                              null,
                                                              { item: item }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                    _vm._t("body", null, { item: item }),
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "content-collapse",
                                    class: { "is-openned": _vm.isCollapseOpen },
                                  },
                                  [
                                    _vm.currCollapseDetails.item === item
                                      ? _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "list style-mode--list",
                                          },
                                          _vm._l(
                                            _vm.currCollapseDetails.list,
                                            function (cell, z) {
                                              return _c("li", {
                                                key: z,
                                                domProps: {
                                                  innerHTML: _vm._s(cell),
                                                },
                                              })
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.$hasSlot("actions"),
                                        expression: "$hasSlot('actions')",
                                      },
                                    ],
                                    staticClass: "actions pr-2 py-2",
                                  },
                                  [
                                    _vm._t("actions", null, {
                                      item: item,
                                      index: i,
                                      showCollapseDetails:
                                        _vm.showCollapseDetails,
                                      isCollapseOpen: _vm.isCollapseOpen,
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : _c("div", [
                  _c("span", [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(987795, "No items for this list")) +
                        "\n\t\t\t"
                    ),
                  ]),
                ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }