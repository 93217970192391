var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      class: _vm.customClass,
      attrs: {
        "header-class": "header-class-modal-doc-package",
        "ok-only": _vm.okOnly,
        size: _vm.size,
        title: _vm.title,
        "ok-variant": "success",
      },
      on: {
        ok: _vm.handleModalOk,
        close: _vm.handleModalClose,
        hide: _vm.handleModalHide,
      },
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c("div", { key: _vm.contentRefreshToken, staticClass: "content" }, [
        _vm.$hasSlot("header")
          ? _c(
              "div",
              { staticClass: "base-modal-header" },
              [_vm._t("header")],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "base-modal-body" }, [_vm._t("default")], 2),
        _vm.$hasSlot("footer")
          ? _c(
              "div",
              { staticClass: "base-modal-footer" },
              [_vm._t("footer")],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }